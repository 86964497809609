import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { forwardRef } from "react"
import styled, { css } from "styled-components"

const Button = styled.button`
  position: relative;
  min-width: 110px;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  max-width: 325px;
  margin: auto 0 0 auto;
  display: inline-flex;
  text-align: center;
  border-radius: 6px;
  font-size: 1.25rem;
  line-height: ${({ small }) => (small ? "28px" : "40px")};
  font-weight: 600;
  border: none;
  padding: 10px;
  div {
    margin: 0 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span {
    font-size: 1rem;
    font-weight: 400;
    display: block;
  }
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
  &:disabled,
  &:disabled:hover {
    color: #fff;
    background: ${({ theme }) => theme.colors.veryLightBlue};
    border-color: ${({ theme }) => theme.colors.veryLightBlue};
  }
  .lazyload-placeholder {
    display: inline-block;
  }
`

const PrimaryBase = css`
  color: ${props => props.theme.colors.darkBlue};
  background: ${props => props.theme.colors.lightGreenishBlue};
`

const PrimaryInverted = css`
  color: ${props => props.theme.colors.lightGreenishBlue};
  background: transparent;
`

const PrimaryStyle = ({ inverted }) =>
  !inverted
    ? css`
        ${PrimaryBase}

        &:hover {
          ${PrimaryInverted}
        }
      `
    : css`
        ${PrimaryInverted}

        &:hover {
          ${PrimaryBase}
        }
      `

const Primary = styled(Button)`
  padding: 8px 10px;
  border: 2px solid ${props => props.theme.colors.lightGreenishBlue};
  ${PrimaryStyle}
`

export const PrimaryButton = forwardRef(({ children, forwardedAs, ...rest }, ref) => (
  <Primary ref={ref} as={forwardedAs} {...rest}>
    <div>{children}</div>
  </Primary>
))

const Secondary = styled(Button)`
  color: #fff;
  background: linear-gradient(to right, ${props => props.theme.colors.clearBlue}, #1c67e5);
  vertical-align: top;
  &:hover {
    background: ${props => props.theme.colors.clearBlue};
  }
  &:before {
    content: "";
    background: #000;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: -10px;
    opacity: 0.4;
    filter: blur(10px);
    z-index: -1;
  }
`

export function SecondaryButton(props) {
  const { children, ...rest } = props
  return (
    <Secondary {...rest}>
      <div>{children}</div>
    </Secondary>
  )
}

const Simple = styled(Button)`
  color: ${props => props.theme.colors.darkBlue};
  background: transparent;
  border: 2px solid transparent;
  &:hover {
    border: 2px solid ${props => props.theme.colors.lightGreenishBlue};
  }
  div {
    margin: 0;
  }
  svg {
    color: ${props => props.theme.colors.lightGreenishBlue};
  }
`

export function SimpleButton(props) {
  const { children, ...rest } = props
  return (
    <Simple {...rest}>
      <div>{children}</div>
    </Simple>
  )
}

const Phone = styled.a`
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  color: ${props => props.theme.colors.primary};
  border: 1px solid;
  border-color: ${({ dark, theme }) => (dark ? theme.colors.darkBlue : "#fff")};
  border-radius: 6px;
  font-size: 24px;
  line-height: 32px;
  padding: 0.5rem 1rem;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
  }
  @media (min-width: 400px) {
    font-size: 32px;
  }
`

export function CallUsButton(props) {
  return (
    <Phone href="tel:+34910782787" {...props}>
      <FontAwesomeIcon icon={["fam", "phone"]} size="xs" className="mr-3" />
      910 782 787
    </Phone>
  )
}

const SmallPhone = styled(Phone)`
  border-width: 2px;
`

export function SmallCallUsButton(props) {
  return (
    <SmallPhone href="tel:+34910782787" {...props}>
      <FontAwesomeIcon icon={["fam", "phone"]} size="xs" />
    </SmallPhone>
  )
}
