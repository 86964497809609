import Link from "next/link"
import { useEffect, useRef, useState } from "react"
import CookieBanner from "react-cookie-banner"

const cookiesStyle = {
  banner: {
    position: "fixed",
    bottom: "0",
    left: "0",
    right: "0",
    fontFamily: "Source Sans Pro",
    background: "rgba(52, 64, 81, 0.88) 20px 50% no-repeat",
    backgroundSize: "30px 30px",
    fontSize: "15px",
    fontWeight: 600,
    height: "auto"
  },
  button: {
    border: "1px solid white",
    borderRadius: 4,
    height: 32,
    lineHeight: "32px",
    background: "transparent",
    color: "white",
    fontSize: "14px",
    fontWeight: 600,
    opacity: 1,
    right: 20,
    marginTop: -18
  },
  message: {
    display: "block",
    padding: "9px 9px",
    lineHeight: 1.3,
    textAlign: "left",
    marginRight: 120,
    color: "white"
  },
  link: {
    textDecoration: "none",
    fontWeight: "bold"
  }
}

export default function CookiesBanner() {
  const [show, setShow] = useState(false)
  const mounted = useRef(true)

  useEffect(() => {
    setTimeout(() => {
      if (mounted.current) setShow(true)
    }, 10)

    return () => {
      mounted.current = false
    }
  }, [])

  if (!show) return null

  return (
    <div>
      <CookieBanner
        styles={cookiesStyle}
        message="Al continuar aceptas el uso de cookies en nuestro sitio web."
        cookie="user-has-accepted-cookies"
        buttonMessage="De acuerdo"
        dismissOnScrollThreshold={100}
        link={
          <span>
            <Link href="/cookies" prefetch={false}>
              <a rel="noindex nofollow noreferrer noopener" target="_blank">
                "Puedes ver nuestra política de cookies aquí"
              </a>
            </Link>
          </span>
        }
      />
    </div>
  )
}
