import Image from "next/image"
import { useRouter } from "next/router"
import Container from "reactstrap/lib/Container"
import styled from "styled-components"

import Car from "@/components/icons/car"
import Icon from "@/components/icons/icon"
import Timer from "@/components/icons/timer"
import { PrimaryButton } from "@/components/styled/Buttons"
import Typography from "@/components/styled/Typography"
import { sendEvent } from "@/scripts/GoogleAnalytics"
import theme from "@/theme"

const carSize = {
  xl: { w: 755, h: 272 },
  lg: { w: 724, h: 261 },
  md: { w: 618, h: 233 },
  xs: { w: 365, h: 150 }
}

const Wrapper = styled.div`
  --top: 105px;

  position: relative;
  color: #fff;
  top: calc(-1 * var(--top));
  margin-bottom: calc(-1 * var(--top));
  padding-top: var(--top);
  overflow: hidden;

  @media ${theme.breakpoints.md} {
    --top: 175px;
  }
`

const BackgroundContainer = styled.div`
  position: absolute;
  top: calc(-1 * var(--top));
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  > div {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      mix-blend-mode: multiply;
      z-index: 0;
      box-shadow: 0 24px 59px 0 rgba(51, 59, 69, 0.15);
      background-image: linear-gradient(210deg, ${theme.colors.clearBlue}, ${theme.colors.darkBlue} 90%);

      @media ${theme.breakpoints.md} {
        background-image: linear-gradient(228deg, ${theme.colors.clearBlue}, ${theme.colors.darkBlue} 93%);
      }
      @media ${theme.breakpoints.lg} {
        background-image: linear-gradient(232deg, ${theme.colors.clearBlue}, ${theme.colors.darkBlue} 94%);
      }
      @media ${theme.breakpoints.xl} {
        background-image: linear-gradient(237deg, ${theme.colors.clearBlue}, ${theme.colors.darkBlue} 94%);
      }
    }
  }
`

const Title = styled.h1`
  && {
    width: calc(100% - 30px);
    min-height: 100px;
    margin: 0 auto;
    color: white;
    font-size: 38px;
    line-height: 44px;
    font-weight: 300;
    max-width: 600px;

    @media ${theme.breakpoints.md} {
      width: 100%;
      font-size: 40px;
      line-height: 46px;
    }

    @media ${theme.breakpoints.xl} {
      max-width: 100%;
    }

    span {
      display: block;

      @media ${theme.breakpoints.sm} {
        display: inline-block;
      }
    }
  }
`

const HighlightedText = styled.span`
  color: ${theme.colors.primary};
  font-weight: 600;
  margin-left: 10px;
`

const ContainerStyled = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const Button = styled(PrimaryButton)`
  height: 60px;
  margin: 25px auto 0;
  box-shadow: 0 4px 24px 0 rgba(109, 236, 185, 0.8);
  border-radius: 8px;
  border: none;
  background-image: linear-gradient(103deg, ${theme.colors.lightMint} -30%, ${theme.colors.lightGreenishBlue} 70%);

  &:hover {
    box-shadow: none;
    color: ${theme.colors.darkBlue};
    background: ${theme.colors.lightMint};
  }

  h5 {
    margin: 0;
  }

  div {
    height: 100%;
  }
`

const Description = styled.div`
  position: relative;
  margin-top: -40px;
  padding: 50px 0;
  text-align: center;
  border-radius: 25px 25px 0 0;
  background: ${theme.colors.navyBlue};
  z-index: -1;

  @media ${theme.breakpoints.md} {
    margin-top: -60px;
    padding-top: 70px;
  }

  @media ${theme.breakpoints.lg} {
    margin-top: -70px;
    padding-top: 80px;
  }

  h3 {
    max-width: 600px;
    margin: 0 auto;

    @media (min-width: 1200px) {
      max-width: 100%;
    }
  }
`

const HeaderImage = styled.div`
  margin-top: 65px;

  img {
    width: ${carSize.xs.w}px;
    height: ${carSize.xs.h}px;
    object-fit: contain;
  }

  @media ${theme.breakpoints.md} {
    margin-top: 55px;

    img {
      width: ${carSize.md.w}px;
      height: ${carSize.md.h}px;
    }
  }

  @media ${theme.breakpoints.lg} {
    margin-top: 50px;
    padding-right: 50px;

    img {
      width: ${carSize.lg.w}px;
      height: ${carSize.lg.h}px;
    }
  }

  @media ${theme.breakpoints.xl} {
    margin-top: 75px;
    padding-right: 0;

    img {
      width: ${carSize.xl.w}px;
      height: ${carSize.xl.h}px;
    }
  }
`

const getImageUrl = (size, factor = 1) => {
  const { w, h } = carSize[size]
  const width = `w_${w * factor},`
  const height = `,h_${h * factor},`

  return `${process.env.NEXT_PUBLIC_CLOUDINARY}${width}${height},q_auto/v1/micochederenting/mcdr-cars-${size}`
}

const getSrcSet = size => `${getImageUrl(size)}, ${getImageUrl(size, 2)} 2x, ${getImageUrl(size, 3)} 3x`

export default function Header() {
  const router = useRouter()

  const onClick = () => {
    sendEvent("Click CTA", { event_category: "homepage", event_label: "main header CTA" })
    return router.push("/multistep")
  }

  return (
    <Wrapper>
      <BackgroundContainer>
        <Image alt="MiCocheDeRenting background" src="/micochederenting/home-header" layout="fill" objectFit="cover" />
      </BackgroundContainer>
      <ContainerStyled>
        <Title>
          Estrena coche de renting en
          <HighlightedText>5 minutos</HighlightedText>
        </Title>
        <Button width={275} onClick={onClick}>
          <Typography variant="h5" className="d-inline-block">
            Ver ofertas
          </Typography>
          <Icon Icon={Car} size="lg" style={{ marginLeft: "10px" }} />
        </Button>
        <HeaderImage>
          <picture>
            <source srcSet={getSrcSet("xl")} media={theme.breakpoints.xl} />
            <source srcSet={getSrcSet("lg")} media={theme.breakpoints.lg} />
            <source srcSet={getSrcSet("md")} media={theme.breakpoints.md} />
            <img alt="Header car" srcSet={getSrcSet("xs")} src={`${getImageUrl("xs")}`} />
          </picture>
        </HeaderImage>
      </ContainerStyled>
      <Description>
        <Timer />
        <Container>
          <Typography variant="h3" className="py-2 mt-3">
            Estudiamos tu solicitud al momento
          </Typography>
          <Typography variat="body">Te llamamos para darte respuesta inmediata ¡Olvídate del papeleo!</Typography>
        </Container>
      </Description>
    </Wrapper>
  )
}
