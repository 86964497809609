import Head from "next/head"
import { useRouter } from "next/router"
import * as PropTypes from "prop-types"

function SEOData(props) {
  const { title, description, image, noIndex } = props
  const router = useRouter()

  const href = `https://micochederenting.com${router.asPath}`

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta property="og:image" content={`${process.env.NEXT_PUBLIC_CLOUDINARY_AUTO}${image}`} />
      <link rel="canonical" href={href} />
      {noIndex && <meta name="robots" content="noindex" />}
    </Head>
  )
}

SEOData.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  noIndex: PropTypes.bool
}

SEOData.defaultProps = {
  image: "logo_black.png",
  title: null,
  description: null,
  noIndex: false
}

export default SEOData
