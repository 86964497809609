export default function Timer() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#6DECB9"
          d="M31.532 17.906c3.042-.18 5.242.537 7.447 1.859l3.78-5.781c-3.28-1.698-7.122-2.678-11.227-2.69v6.612z"
        />
        <path
          fill="#6780AA"
          fillOpacity=".2"
          d="M11.575 38.706h3.584c.56 0 1.012-.457 1.012-1.019 0-.562-.453-1.019-1.012-1.019h-3.597c.351-10.784 7.683-20.065 18.048-22.845v-.785c-12.657.607-22.68 10.898-23.112 23.63h3.598c.56 0 1.012.457 1.012 1.019 0 .562-.453 1.019-1.012 1.019H6.511c.6 12.392 10.61 22.564 23.1 23.165v-.79C19.574 58.387 12.09 49.368 11.574 38.705"
        />
        <path fill="#D4E1F4" d="M31.636 61.53v.355c.573-.02 1.139-.059 1.696-.118-.571-.059-1.137-.138-1.696-.238" />
        <path
          fill="#FFF"
          d="M32.843 61.874l.005.004c-.123.006-.245.013-.369.017v-3.781c0-.563-.453-1.019-1.012-1.019-.56 0-1.013.456-1.013 1.019v3.767C17.969 61.297 7.971 51.263 7.363 38.706h3.584c.56 0 1.013-.456 1.013-1.019s-.453-1.019-1.013-1.019H7.35c.431-12.732 10.447-23.013 23.104-23.62v3.777c0 .563.454 1.019 1.013 1.019.559 0 1.012-.456 1.012-1.019v-3.79c12.658.442 23.007 10.732 23.442 23.633h-3.927c-.56 0-1.013.456-1.013 1.019s.454 1.019 1.013 1.019h3.913c-.08 1.593-.312 3.175-.691 4.724-.008.034-.015.068-.02.102-2.593 10.19-11.502 17.821-22.354 18.342zm.312-54.235v3.391c-.507-.03-1.018-.048-1.545-.048l-.062.001c-.03-.003-.054-.005-.084-.005-.03 0-.06.003-.088.008-.432.004-.922.02-1.26.044V7.64h3.039zm-6.79-2.037c-.165 0-.299-.135-.299-.301V2.508c0-.166.134-.301.3-.301h10.707c.166 0 .3.135.3.301v2.793c0 .166-.134.301-.3.301H26.366zm26.458 10.523c.117-.118.307-.118.424 0l1.996 2.008c.117.118.117.309 0 .426l-2.749 2.766c-.698-.912-1.455-1.778-2.264-2.591l2.593-2.61zm4.57 26.85c.022-.105.041-.21.062-.314.041-.21.08-.419.116-.628.022-.122.043-.244.062-.366.019-.118.036-.236.053-.354.018-.124.035-.248.051-.373l.044-.34c.02-.172.038-.346.055-.52l.037-.415c.012-.146.023-.292.033-.439l.019-.326.019-.425.01-.298c.006-.225.01-.45.01-.676v-.044c.004-5.132-1.483-10.154-4.28-14.447L56.676 20c.908-.913.908-2.394 0-3.307l-1.997-2.009c-.907-.913-2.38-.913-3.287 0l-2.676 2.666c-3.822-3.325-8.527-5.452-13.535-6.12V7.64h1.782c1.237.015 2.252-.98 2.268-2.225V2.508c.025-1.266-.976-2.313-2.235-2.338H26.309c-1.259.006-2.275 1.037-2.268 2.304v2.874c-.021 1.244.965 2.27 2.201 2.291h1.851v3.591c-12.828 1.769-22.76 12.86-22.76 26.235 0 14.485 11.597 26.29 25.954 26.478 0 0 3.436.149 6.651-.72 9.425-2.325 16.847-9.79 19.222-19.249.007-.023.015-.046.02-.07.023-.09.043-.182.064-.273l.052-.224.096-.432z"
        />
        <path
          fill="#6DECB9"
          fillRule="nonzero"
          d="M28.206 48.282c-1.404 0-2.62-.254-3.65-.762-1.029-.508-1.815-1.172-2.358-1.99-.542-.82-.832-1.7-.87-2.64v-.057c0-.15.056-.273.169-.367.112-.094.243-.141.393-.141h1.375c.393 0 .646.207.758.62.28 1.017.8 1.775 1.558 2.274.758.499 1.633.748 2.625.748 1.272 0 2.33-.39 3.171-1.172.843-.78 1.264-1.859 1.264-3.233 0-1.26-.421-2.263-1.264-3.007-.842-.743-1.899-1.115-3.171-1.115-.674 0-1.217.085-1.629.254-.411.17-.832.414-1.263.734-.243.188-.444.325-.603.41-.16.084-.332.127-.52.127h-1.347c-.168 0-.318-.061-.449-.184-.131-.122-.187-.268-.168-.437l.87-9.403c.056-.47.29-.706.701-.706h9.713c.206 0 .365.057.477.17.112.113.168.273.168.48v1.044c0 .189-.06.34-.182.452-.122.113-.276.17-.463.17h-8.084l-.506 5.675c.899-.64 2.106-.96 3.621-.96 1.254 0 2.396.25 3.425.748 1.03.499 1.848 1.228 2.456 2.188.608.96.912 2.1.912 3.417 0 1.393-.304 2.593-.912 3.6-.608 1.007-1.45 1.77-2.526 2.287-1.076.518-2.307.776-3.691.776zm10.497-14.964c-.15 0-.262-.052-.337-.156-.075-.103-.103-.25-.084-.437l.617-3.953c.075-.546.337-.82.786-.82h1.797c.13 0 .238.048.323.142.084.094.126.207.126.339 0 .188-.028.339-.084.452l-1.46 3.811c-.094.208-.197.363-.309.466-.112.104-.28.156-.505.156h-.87z"
        />
      </g>
    </svg>
  )
}
