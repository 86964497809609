import dynamic from "next/dynamic"
import { useContext } from "react"

import { ModalContext } from "./ModalContext"

const WhatsappConsent = dynamic(() => import("./WhatsappConsent"))

export default function GlobalModals() {
  const { show, modal } = useContext(ModalContext)

  if (!show) return null

  if (modal === "whatsappConsent") return <WhatsappConsent />

  return null
}
