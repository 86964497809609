import Image from "next/image"
import Link from "next/link"
import Container from "reactstrap/lib/Container"
import styled from "styled-components"

import { CallUsButton, SmallCallUsButton } from "@/components/styled/Buttons"
import { sendEvent } from "@/scripts/GoogleAnalytics"

const Wrapper = styled(Container)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const NavBar = styled.nav`
  padding: 10px 0;
  background: ${({ backgroundColor, theme }) => theme.colors[backgroundColor] || "transparent"};
  position: relative;
  z-index: 9;

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 2rem 0;
  }
`

const Phone = styled.div`
  float: right;
  margin-top: 0.5rem;
`

const LogoWrapper = styled.div`
  > a {
    &:last-child {
      display: none;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
      &:first-child {
        display: none;
      }

      &:last-child {
        display: block;
      }
    }
  }
`

export default function TopBar({ dark, pageName, backgroundColor }) {
  const onClickCTA = () => {
    sendEvent("incoming call", { event_category: pageName, event_label: "incoming call header" })
  }

  return (
    <NavBar backgroundColor={backgroundColor}>
      <Wrapper>
        <LogoWrapper>
          <Logo dark={dark} />
          <Logo dark={dark} big />
        </LogoWrapper>
        <Phone className="d-none d-lg-block">
          <CallUsButton dark={dark} onClick={onClickCTA} />
        </Phone>
        {dark && (
          <Phone className="d-block d-lg-none">
            <SmallCallUsButton dark={dark} onClick={onClickCTA} />
          </Phone>
        )}
      </Wrapper>
    </NavBar>
  )
}

TopBar.defaultProps = {
  dark: false
}

const Logo = ({ big, dark }) => (
  <Link href="/">
    <a>
      <Image
        alt="MiCocheDeRenting Logo"
        height={big ? 80 : 40}
        width={big ? 264 : 132}
        src={`/f_svg/micochederenting/logo/logo-mcdr-${dark ? "dark" : "light"}`}
      />
    </a>
  </Link>
)
