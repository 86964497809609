import dynamic from "next/dynamic"
import * as PropTypes from "prop-types"
import LazyLoad from "react-lazyload"

import GlobalModals from "../../Modals/GlobalModals"
import ModalContextProvider from "../../Modals/GlobalModals/ModalContext"
import TopBar from "../../TopBar"
import CookiesBanner from "../CookiesBanner"

const Footer = dynamic(() => import("../../Footer"), { ssr: false })

export default function DefaultLayout(props) {
  const { children, topBarProps = {}, footerProps = {} } = props

  return (
    <ModalContextProvider>
      <TopBar {...topBarProps} />
      {children}
      <CookiesBanner />
      <LazyLoad height={200}>
        <Footer {...footerProps} />
      </LazyLoad>
      <GlobalModals />
    </ModalContextProvider>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired
}
