import styled from "styled-components"

const defaultCss = `
  margin: 0;
  letter-spacing: 0;
  font-weight: 600;
`

const H1 = styled.h1`
  ${defaultCss}
  font-size: 60px;
  line-height: 64px;
  margin-bottom: 0.5em;
`

const H2 = styled.h2`
  ${defaultCss}
  font-size: 40px;
  line-height: 1.15;
  margin-bottom: 0.5em;
  .light {
    font-weight: 300;
  }
`

const H3 = styled.h3`
  ${defaultCss}
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 0.5em;
  @media (min-width: 992px) {
    font-size: 36px;
    line-height: 42px;
  }
`

const H4 = styled.h4`
  ${defaultCss}
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 0.5em;
`

const H5 = styled.h5`
  ${defaultCss}
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 0.5em;
`

const H6 = styled.h6`
  ${defaultCss}
  font-size: 21px;
  line-height: 28px;
  margin-bottom: 0.5em;
`

const H7 = styled.p`
  ${defaultCss}
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 1em;
`

const Small = styled.small`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0.5em;
  display: block;
  font-weight: 300;
`

const Body = styled.p`
  font-size: 1em;
  margin: 0 0 1.5em;
  line-height: 1.5;
  hyphens: auto;
`

const elements = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  h7: H7,
  small: Small,
  body: Body
}

export default function Typography({ variant, children, ...rest }) {
  const El = elements[variant] || Body

  return <El {...rest}>{children}</El>
}

Typography.defaultProps = {
  variant: "body"
}
