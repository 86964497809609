import DefaultLayout from "components/layouts/DefaultLayout"
import SEOData from "components/SEOData"
import dynamic from "next/dynamic"
import LazyLoad from "react-lazyload"

import Header from "./Header"

const Testimonials = dynamic(() => import("./Testimonials"), { ssr: false })
const Conditions = dynamic(() => import("./Conditions"), { ssr: false })
const Offers = dynamic(() => import("./Offers"), { ssr: false })

export default function HomeScene() {
  return (
    <DefaultLayout topBarProps={{ pageName: "homepage" }} footerProps={{ pageName: "homepage" }}>
      <SEOData
        title="Encuentra tu coche de Renting en 5 minutos | MiCochedeRenting.com"
        description="Elige entre más de 70 modelos el coche que mejor se adapte a lo que buscas. Hazlo todo sin salir de casa. ¡Te lo ponemos fácil! El proceso es 100% online."
      />
      <Header />
      <LazyLoad height={500} once>
        <Offers />
        <Conditions />
        <Testimonials />
      </LazyLoad>
    </DefaultLayout>
  )
}
